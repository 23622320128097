
import Swal from 'sweetalert2'
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import { InputSearch, Button, StudyCard, TestPreview } from '@/components'
import Counter from '~/components/Counter/Counter.vue'
import { parseProxyToObject } from '@/utils'
import Pagination from 'v-pagination-3';
import store from '@/store'
import ProgressModal from '@/components/StudyCreation/ProgressModal.vue';
export default defineComponent({
  name : 'Dashboard',
  components:{
    InputSearch,
    Button,
    Pagination,
    StudyCard,
    Counter,
    TestPreview,
    ProgressModal
  },
  computed: {
    ...mapGetters([
      'clientStudies'
    ])
  },
  data(){
    return{
      studiesPage:1,
      studySearchText:'',
      studyCreatedType:0,
      searchBarDelay:0,
      executionPreviewTestId:'',
      showExecutionPreview:false,
      downloadingExcel: false,
      booleanStudyCard:false,
      showStudyCards: true
    }
  },
  methods:{
    handleEvent(booleanStudyCard: boolean) {
      this.downloadingExcel = booleanStudyCard;
    },
    searchStudiesByText(){
      clearTimeout(this.searchBarDelay)
      this.searchBarDelay = setTimeout(
        ( ) => this.getClientStudies(), 500
      );
    },
    async getClientStudies(){
      const filter = {
        page:this.studiesPage - 1, 
        pageSize:10, 
        search:this.studySearchText, 
        studyCreatedType: this.studyCreatedType == 0 ? undefined : this.studyCreatedType
      }
      await store.dispatch('getClientStudies', filter)
    },
    async getClientStudy(studyId: Number, study: any){
      await store.dispatch('getClientStudy',{studyId:studyId})
      .then((data) => {
        study.extendedStudy = data
      })
    },
    changeStudiesPage(){
      this.getClientStudies()
    },
    openTestPreview(testId: string){
      this.executionPreviewTestId = testId
      this.showExecutionPreview = true
    },
    async deleteStudy(study: any){
      let vm = this
      await store.dispatch('deleteStudy',{studyId: study.contractId})
      .then((data) => {
        Swal.fire({
          title:vm.$t('suite_swal_study_deleted'),
          icon:"success",
          customClass:{popup:"swal-customSuccess"}
        })
        this.reloadCards()
        this.getClientStudies()
      })
    },
    async finishStudy(study: any){
      let vm = this
      await store.dispatch('finishStudy',{studyId: study.contractId})
      .then((data) => {
        Swal.fire({
          title:vm.$t('suite_swal_study_finished'),
          text: vm.$t('suite_swal_study_finished_text'),
          icon:"success",
          customClass:{popup:"swal-customSuccess"}
        })
        this.reloadCards()
        this.getClientStudies()
      })
    },
    beforeDuplicateStudy(study: any){
      let vm = this
        Swal.fire({
          title: vm.$t('suite_swal_study_name'),
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: vm.$t('suite_action_duplicate'),
          cancelButtonText: vm.$t('suite_action_cancel'),
          showLoaderOnConfirm: true,
          preConfirm: (studyName) => {
            vm.duplicateStudy(study, studyName)
          }
        })
    },
    async duplicateStudy(study: any, studyName: any){
      let vm = this
      await store.dispatch('duplicateStudy',{studyId: study.contractId, title: studyName})
      .then((data) => {
        Swal.fire({
          title:vm.$t('suite_swal_study_duplicated'),
          icon:"success",
          customClass:{popup:"swal-customSuccess"}
        })
        this.reloadCards()
        this.getClientStudies()
      })
    },
    reloadCards() {
      this.showStudyCards = false;
      setTimeout(() => {
        this.showStudyCards = true;
      }, 1);
    }
  },
  mounted(){
    this.getClientStudies()
  }
})
